code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}
ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background-color: #eee;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  overflow-x: hidden;
  font-family: "Light";
  color: rgb(var(--foreground-rgb));
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #383f42;
  border-radius: 20px;
  border: 3px solid white;
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.active-carousel-item {
  border-image-outset: 8
}

/*
  Font Faces (variations and different fonts)
  - https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-weight
*/
@font-face {
  font-family: "Rigid Square"; /*Can be any text*/
  font-weight: bold;
  src: local("RigidSquare-Bold"),
    url("./assets/fonts/RigidSquare-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Rigid Square"; 
  font-weight: 600;
  src: local("RigidSquare-SemiBold"),
    url("./assets/fonts/RigidSquare-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Rigid Square"; 
  font-weight: normal;
  src: local("RigidSquare-Regular"),
    url("./assets/fonts/RigidSquare-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rigid Square"; 
  font-weight: 300;
  src: local("RigidSquare-Light"),
    url("./assets/fonts/RigidSquare-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Rigid Square"; 
  font-weight: 100;
  src: local("RigidSquare-Thin"),
    url("./assets/fonts/RigidSquare-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Inter"; 
  font-weight: bold;
  src: local("Inter-Bold"),
    url("./assets/fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter"; 
  font-weight: normal;
  src: local("Inter-Regular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}